import React from 'react'
import classNames from 'classnames';
import PropTypes from 'prop-types'
import styles from './FullWidth.module.scss'

const FullWidth = ({ children, className }) => (
  <div className={classNames(styles.fullWidth, className)}>{children}</div>
)

FullWidth.propTypes = {
  children: PropTypes.node,
  className: PropTypes.element
}

export default FullWidth
